import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Aos from 'aos';
import ScreenHead from './screenHead';
const data = [
  {
    title: 'Бүх анги',
    image: '/assets/teachersSticker/enko.png',
    link: '/courses',
    type: '',
  },
  {
    title: 'Xүүхэд',
    image: '/assets/teachersSticker/doogii.png',
    link: '/courses/child',
    type: 'C',
  },
  {
    title: 'Насанд хүрэгчид',
    image: '/assets/teachersSticker/sodoo.png',
    link: '/courses/adult',
    type: 'A',
  },
  {
    title: 'Багш нар',
    image: '/assets/teachersSticker/usuhoo.png',
    link: '/courses/teachers',
    type: 'B',
  },
];

const coursesScreen = () => {
  const router = useRouter();
  const { pathname, query } = router;
  return (
    <>
      <div className="mt-[50px]">
        <div className="max-w-7xl mx-auto px-4">
          <Link href={`/courses`}>
            <div className="hidden  cursor-pointer md:flex items-center justify-center text-[40px] font-bold text-white bg-[#f91d87] p-[10px] m-4">
              <img
                src="/assets/contentnameimages/courses.png"
                alt="shop-usukhod"
                className="w-[60px] h-[60px] 
            "
              />
              <h1 className="ps-2">Сургалт</h1>
            </div>
          </Link>

          <div className=" md:hidden pb-4">
            <ScreenHead title="Сургалт" link="/course" />
          </div>

          <div className={`grid grid-cols-4 gap-4 items-center`}>
            {data?.map((data, idx) => {
              return (
                <div
                  key={idx}
                  className="flex items-center justify-center flex-col cursor-pointer"
                  onClick={() =>
                    router.push({
                      pathname: 'courses',
                      query: { ...query, type: data?.type },
                    })
                  }
                >
                  <img
                    src={data.image}
                    alt="usukhod"
                    className="w-[60px] h-60px
                   sm:w-[80px] sm:h-[80px] md:w-[100px] md:h-[100px] lg:w-[150px] lg:h-[150px]
                "
                  />
                  <p className="text-[8px] md:text-lg font-bold">
                    {data.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default coursesScreen;
