import React, { useEffect } from 'react';
import ScreenHead from './screenHead';
import { useRouter } from 'next/router';
import { useGetAllCategory } from '@services/shop';
import Link from 'next/link';

const shopScreen = () => {
  const router = useRouter();

  const { pathname, query } = router;

  const { data: categoryData, loading: loadingCategory } = useGetAllCategory({
    subUrl: '/api/sales/category/',
  });

  function handleCategoryData(id) {
    let categoryId = id;
    if (categoryId === 'all') {
      router.push('/shop');
    } else {
      router.push({
        pathname: '/shop',
        query: { ...query, category_id: categoryId },
      });
    }
  }
  return (
    <div className="mt-[50px]">
      <div className={`max-w-7xl mx-auto px-4 `}>
        <Link href={`/shop`}>
          <div className="hidden  md:flex items-center justify-center text-[40px] font-bold text-white bg-[#00bfec] p-[10px] m-4">
            <img
              src="/assets/contentnameimages/shop.png"
              alt="shop-usukhod"
              className="w-[60px] h-[60px] 
            "
            />
            <h1 className="ps-2">Дэлгүүр</h1>
          </div>
        </Link>
        <div className=" md:hidden">
          <ScreenHead title="Дэлгүүр" link="/shop" />
        </div>
        <div>
          <div className="p-4 pb-0 font-Rubik text-[20px] md:text-[24px] md:font-semibold tracking-widest">
            <h1>Ангилал</h1>
          </div>
          <div className=" grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-4 items-center justify-center p-4">
            {categoryData &&
              categoryData?.result.map((category, i) => (
                <div
                  onClick={() => handleCategoryData(category?.id)}
                  className="flex items-center justify-between flex-col bg-[#F2F4F6] h-[120px] w-[95px] md:h-[190px] md:w-[140px] rounded-lg p-3 shadow-lg"
                  key={i}
                >
                  <img
                    src={category?.picture}
                    alt="category usukh-od"
                    className="h-[120px] object-cover object-center"
                  />
                  <p className="text-[12px] md:text-[12px] tracking-widest">
                    {category?.name}
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default shopScreen;
