import React, { useEffect } from 'react';
import Link from 'next/link';
import Aos from 'aos';

const screenHead = props => {
  return (
    <>
      <div
        className={`flex md:hidden items-center justify-between m-4 cursor-pointer`}
      >
        <div className="flex items-center border-l-8 border-[#f91d87]">
          <h1 className={`text-[24px] font-bold tracking-[1px] p-2`}>
            {props?.title}
          </h1>
        </div>
        <Link href={props?.link}>
          <div className="text-green">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className={`w-6 h-6 text-[#f91d87]`}
            >
              <path
                fillRule="evenodd"
                d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </Link>
      </div>
    </>
  );
};

export default screenHead;
