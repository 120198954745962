import type { NextPage } from 'next';
import Layout from '@components/global/layout';
import Navbar from '@components/global/navbar';
import Slider from '@components/global/slider';
import CoursesScreen from '@components/global/coursesScreen';
import ShopScreen from '@components/global/shopScreen';
import Teachers from '@components/global/teachers';
import Content from '@components/global/content';

const Home: NextPage = () => {
  return (
    <Layout>
      <Navbar />
      <Slider />
      <CoursesScreen />
      <ShopScreen />
      <Teachers />
      <Content />
    </Layout>
  );
};

export default Home;
