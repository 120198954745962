import { fetcherNoToken } from './apiHandler';
import useSWRImmutable from 'swr/immutable';

export const useGetAllTeachers = ({ subUrl }) => {
  const { data, error, ...rest } = useSWRImmutable(
    [process.env.BASE_API_URL + subUrl],
    fetcherNoToken
  );
  return { data, error, loading: !data && !error, ...rest };
};
