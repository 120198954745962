import React, { useState } from 'react';

const Modal = ({ nickName, story }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          setShowModal(!showModal);
        }}
        className="text-white bg-[#00718a] focus:outline-none font-medum text-sm rounded-lg px-5 py-2.5 text-center mt-2 "
      >
        Дэлгэрэнгүй
      </button>
      <div
        onClick={() => setShowModal(false)}
        className={` ${
          showModal ? 'fixed' : 'hidden'
        } inset-0 bg-black bg-opacity-5 backdrop-blur-sm flex justify-center items-center `}
      >
        <div className="w-[90%] mx-auto md:w-[600px] flex flex-col">
          <button
            onClick={() => setShowModal(false)}
            className="text-red-500 text-lg place-self-end "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div className="bg-white p-2 rounded-lg transition ease-in-out delay-150 ">
            <div className="p-6">
              <div className="text-xl font-semibold text-grey-900 mb-5 border-b-2">
                {nickName}
              </div>
              <div className="w-full h-full border-b-2">
                <p>{story}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
