import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ComponentLoading = () => {
  return (
    <>
      {[...Array(15).keys()]?.map((product, i) => (
        <div
          className="bg-white shadow-lg rounded-lg p-4 w-full hover:bg-gray-50 hover:shadow-xl lg:hover:scale-105 cursor-pointer"
          key={i}
        >
          <div className="flex justify-center pb-4">
            <div className="h-32 bg-gray-200 rounded-lg dark:bg-gray-700 w-full"></div>
          </div>
          <div className="">
            <p className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-2"></p>
            <p className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-full"></p>
          </div>
        </div>
      ))}
      ;
    </>
  );
};
export default ComponentLoading;
