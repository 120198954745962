import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Link from 'next/link';
import ScreenHead from './screenHead';

interface VideoItem {
  id: string;
  snippet: {
    title: string;
    thumbnails: {
      medium: {
        width: number;
        height: number;
        url: string;
      };
    };
    resourceId: {
      videoId: string;
    };
  };
}

interface YouTubeResponse {
  items: VideoItem[];
}
export default function Content() {
  const [videos, setVideos] = useState<YouTubeResponse | null>(null);

  useEffect(() => {
    axios
      .get(
        'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=UULFWi8iI7C6rLLJt3w-Z407qA&key=AIzaSyCvQx3eD94iyzDp4hIvIOSWtod9Mjx8WLc'
      )
      .then(res => {
        if (res.status == 200) {
          setVideos(res.data);
        }
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <div className="">
      <div className={`max-w-7xl mx-auto px-4 `}>
        <Link href={``}>
          <div className="hidden  md:flex items-center justify-center text-[40px] font-bold text-white bg-[#f91d87] p-[10px] m-4">
            <img
              src="/assets/contentnameimages/contents.png"
              alt="shop-usukhod"
              className="w-[60px] h-[60px]  
                "
            />
            <h1 className="ps-2">Контенууд</h1>
          </div>
        </Link>
        <div className=" md:hidden">
          <ScreenHead title="Контенууд" link="" />
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4">
        <main className="p-4 mb-5">
          <ul className="grid grid-cols-2 gap-4 xl:grid-cols-6 md:grid-cols-4">
            {videos?.items
              .map(({ id, snippet = {} }) => {
                const title = snippet?.title || 'Default Title';
                const { thumbnails, resourceId } = snippet;
                const { medium }: any = thumbnails;
                return (
                  <li
                    className="bg-white p-4 rounded-lg shadow-lg group-hover:stroke-white hover:border-2 hover:border-[#f91d87] hover:text-[#00718a]"
                    key={id}
                  >
                    <Link
                      href={`https://www.youtube.com/watch?v=${resourceId?.videoId}`}
                      target="_blank"
                    >
                      <p>
                        <img
                          width={medium.width}
                          height={medium.height}
                          src={medium.url}
                          alt=""
                        />
                      </p>
                      <h3 className="font-semibold ">{title}</h3>
                    </Link>
                  </li>
                );
              })
              .slice(0, 12)}
          </ul>
        </main>
      </div>
    </div>
  );
}
