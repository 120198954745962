import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import Button from './button';

const dataSlider = [
  {
    id: 1,
    title: ' Бидний тухай',
    image: '/assets/bannerimg/WEB_PHOTO_01.jpg',
    url: '/aboutus',
  },
  {
    id: 2,
    title: 'Бүгдийг харах',
    image: '/assets/bannerimg/WEB PHOTO_02.jpg',
    url: '/training',
  },
  {
    id: 3,
    title: 'Бүгдийг харах',
    image: '/assets/bannerimg/WEB_PHOTO_03.jpg',
    url: '/shop',
  },
];

export default function Slider() {
  return (
    <div className="mb-2 pt-24">
      <div className="flex justify-center items-center">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          autoplay={{ delay: 2000 }}
          pagination={{ clickable: true }}
          breakpoints={{
            700: {
              slidesPerView: 1,
            },
          }}
          className="mainSlider md:max-h-[500px]"
        >
          {dataSlider?.map((data, idx) => {
            return (
              <SwiperSlide key={idx}>
                <div className="block w-full">
                  <img
                    src={data.image}
                    alt="First slide"
                    className="w-full h-[200px] md:h-[500px] object-cover object-center"
                  />
                </div>
                <div className="absolute right-[10px] bottom-[20px] z-30">
                  <Button
                    desc={data.title}
                    link={data.url}
                    bgColor="#f91d87"
                    hover="white/30"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
