import React from 'react';
import Link from 'next/link';

const button = props => {
  return (
    <>
      <Link href={props.link}>
        <button
          className={`px-[12px] py-[4px] bg-[${
            props.bgColor
          }] opacity-0.5 rounded-md text-white hover:bg-${
            props.hover ? props.hover : ''
          } hover:${
            props.hover ? 'text-black' : 'text-white'
          } text-[8px] md:text-lg`}
        >
          {props.desc}
        </button>
      </Link>
    </>
  );
};

export default button;
