import React, { Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import { useGetAllTeachers } from '@services/teachers';
import ComponentLoading from './componentLoading';
import Modal from '@components/modal/modal';

const Teachers = () => {
  const { data: teachersData, loading: loadingTeachers } = useGetAllTeachers({
    subUrl: '/api/surgalt/teacher/',
  });

  return (
    <Fragment>
      <div className="mt-[50px]">
        <div className={`max-w-7xl mx-auto px-4 `}>
          <div className="hidden  md:flex items-center justify-center text-[40px] font-bold text-white bg-[#f91d87] p-[10px] m-4">
            <img
              src="/assets/contentnameimages/teachersHead.png"
              alt="shop-usukhod"
              className="w-[60px] h-[60px] 
            "
            />
            <h1 className="ps-2">Багш нар</h1>
          </div>

          <div className=" md:hidden">
            <div
              className={`flex md:hidden items-center justify-between m-4 cursor-pointer`}
            >
              <div className="flex items-center border-l-8 border-[#f91d87]">
                <h1 className={`text-[24px] font-bold tracking-[1px] p-2`}>
                  Багш нар
                </h1>
              </div>

              <div className="text-green">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={`w-6 h-6 text-[#f91d87]`}
                >
                  <path
                    fillRule="evenodd"
                    d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className={`max-w-7xl mx-auto px-4 `}>
          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            modules={[Navigation, Autoplay]}
            navigation
            autoplay={{ delay: 2000 }}
            pagination={{ clickable: true }}
            breakpoints={{
              1024: {
                slidesPerView: 5,
              },
            }}
            className="mainSlider md:max-h-[500px] p-4"
          >
            {' '}
            {loadingTeachers ? (
              <ComponentLoading />
            ) : (
              teachersData &&
              teachersData?.result.map((teachers, i) => {
                return (
                  <SwiperSlide
                    className="flex flex-col items-center justify-between self-stretch bg-white rounded-lg shadow-lg p-2 "
                    key={i}
                  >
                    {teachers?.picture ? (
                      <div className="bg-[#00718a] rounded-full flex items-center w-28 h-28">
                        <img
                          src={teachers?.picture}
                          alt=""
                          className="rounded-full"
                        />
                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className=" w-28 h-28"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                    <div className="flex flex-col items-center justify-center">
                      <h2>{teachers?.firstname}</h2>
                      <p className="text-[12px] text-[#00718a] font-semibold truncate max-w-[120px]">
                        {teachers?.level_name}
                      </p>
                    </div>
                    <div>
                      <a
                        href={teachers?.social}
                        className="cursor:pointer"
                        target="_blanck"
                      >
                        <p className="text-[12px] font-semibold">social...</p>
                      </a>
                    </div>
                    <Modal
                      nickName={teachers?.nick_name}
                      story={teachers?.story}
                    />
                  </SwiperSlide>
                );
              })
            )}
          </Swiper>
        </div>
      </div>
    </Fragment>
  );
};

export default Teachers;
